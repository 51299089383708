'use strict';

angular.module('ltcMobileApp')
    .value('version', '')
    .config(['$httpProvider', function($httpProvider) {
        // Configure $http service defaults
        $httpProvider.defaults.withCredentials = true;
    }])
    .run(['$rootScope', function($rootScope) {
        // Any application initialization logic
        console.log('Application initialized');
    }]);
