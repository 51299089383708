'use strict';

/**
 * Event service
 * Manages event data and provides utility functions
 */
angular.module('ltcMobileApp.services')
    .service('EventModel', ['dateTimeUtil', function(dateTimeUtil) {
        var self = this;
        this.events = {};
        this.site_name = '';

        this.Event = function(key) {
            this.event_key = key;
            this.participation_key = null;
            this.start_time = '';
            this.duration = 0;
            this.location = '';
            this.event_name = '';
            this.attendees = 0;
            this.day = '';
        };

        this.Event.prototype.formatTimeWithAllDay = function() {
            if (this.duration == 0) {
                return 'all day';
            } else {
                return dateTimeUtil.formatTime(this.start_time);
            }
        };

        this.Event.prototype.get_end_time = function() {
            return new Date(this.start_time.getTime() + this.duration*1000);
        };

        this.load_event_object = function(event_obj, event) {
            event_obj.participation_key = event['participation_key'];
            event_obj.event_key = event['event_key'];
            event_obj.start_time = new Date(event['start_time']);
            event_obj.duration = event['duration'];
            event_obj.location = event['location'];
            event_obj.event_name = event['event_name'];
            event_obj.attendees = Number(event['attendees']) || 0;
            event_obj.students = event['students'];
            event_obj.day = dateTimeUtil.formatDate(event_obj.start_time);
            // friday or saturday
            event_obj.day_num = event_obj.start_time.getDay() == 5 ? 'one' : 'two';
        };

        this.load_events = function(response) {
            var new_events = [];
            if (!(response instanceof Array)) {
                response = [response];
            }
            for (var i in response) {
                var event = response[i];
                var key;
                if (typeof event === "string") {
                    key = event;
                } else {
                    key = event['event_key'];
                }
                var event_obj = self.events[key];
                if (event_obj == null) {
                    event_obj = new self.Event(event['event_key']);
                }
                if (!(typeof event === "string")) {
                    self.load_event_object(event_obj, event);
                }
                self.events[key] = event_obj;
                new_events.push(event_obj);
            }
            return new_events;
        };
        

        this.event_time_comparator = function(a, b) {
            if (a.start_time < b.start_time) {
                return -1;
            }
            if (b.start_time < a.start_time) {
                return 1;
            }
            if (a.event_name < b.event_name) {
                return -1;
            }
            if (b.event_name < a.event_name) {
                return 1;
            }
            return 0;
        };

        this.clear = function() {
            self.events = {};
        };
    }]);
