'use strict';

/**
 * Login Controller
 * Handles user authentication
 */
angular.module('ltcMobileApp.controllers')
    .controller('LoginCtrl', [
        '$scope', '$location', '$http', '$timeout', '$routeParams', 'UserModel', 'EventModel', 'version', 'dateTimeUtil',
        function($scope, $location, $http, $timeout, $routeParams, UserModel, EventModel, version, dateTimeUtil) {
        UserModel.register({
            'upgrade_required': function() {
                window.location.reload();
            }
        });
        dateTimeUtil.updateNow();

        UserModel.logout();

        function toast_message(msg, err, load, time) {
            $scope.message = msg;
            $scope.message_error = err == true;
            $scope.message_loading = load == true;
            $scope.message_visible = true;
            if (time) {
                $timeout(function() {
                    $scope.message_visible = false;
                }, time);
            }
        }

        $scope.login = function(code) {
            format_code();
            code = code || $scope.reg_code;
            UserModel.login(code, {
                'success': function() {
                    toast_message('Hello there! Let me check your schedule...', false, true);
                },
                'success2': function() {
                    $location.path('/events');
                },
                'error': function() {
                    toast_message('Login failed.', true, false, 5000);
                }
            });
            toast_message('Please wait...', false, true);
        };

        var key_timeout = null;
        $scope.code_key = function() {
            if (key_timeout) {
                $timeout.cancel(key_timeout);
            }
            key_timeout = $timeout(format_code, 1000);
        };
        function format_code() {
            var code = $scope.reg_code || '';
            code = code.replace(/-/g, '');
            var new_code = code.substr(0, 3);
            if (code.length > 3) {
                new_code += '-' + code.substr(3, 3);
            }
            if (code.length > 6) {
                new_code += '-' + code.substr(6, 3);
            }
            new_code = new_code.toUpperCase();
            $scope.reg_code = new_code;
        };

        $scope.users = UserModel.get_users();

        if (UserModel.get_users('student').length > 1) {
            $scope.all_students = true;
        }
        $scope.login_all_students = function() {
            UserModel.login_all_students({
                'success': function() {
                    $location.path('/events');
                }
            });
        };
        $scope.saved_logins = UserModel.get_users('student').length > 0;
        $scope.clear_logins = function() {
            UserModel.clear_all();
            $scope.users = UserModel.get_users();
            $scope.all_students = false;
        };
        $scope.version = localStorage['app-version'];

        if ($routeParams.code) {
            $scope.login($routeParams.code);
        }
    }]);
