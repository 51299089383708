'use strict';

/**
 * Menu Controller
 * Controls the side menu functionality
 */
angular.module('ltcMobileApp.controllers')
    .controller('MenuCtrl', ['$scope', '$location', 'UserModel', 'VolunteerService', 'urlUtil', function($scope, $location, UserModel, VolunteerService, urlUtil) {
        $scope.user_name = UserModel.current_user_name;
        $scope.mobile_login_id = UserModel.current_user_login_code;
        $scope.is_sponsor = UserModel.current_user_type == 'sponsor';
        $scope.version = localStorage['app-version'];
        
        // Set the menu template URL
        $scope.menu = {};
        $scope.menu.url = '/m/app/components/menu/menu.template.html';
        
        $scope.hide = function() {
            // this doesn't work
            $scope.menu_visible = false;
        };
        
        $scope.navigateTo = function(path) {
            $scope.$parent.menu_visible = false;
            // Only change location if we're not already on that page
            if ($location.path() !== '/' + path) {
                $location.path('/' + path);
            }
        };
        
        $scope.launchMap = function() {
            urlUtil.launchMap();
        };
    }]);
