// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/img/menu/background-hdpi.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Menu component styles */

aside.menu {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  position: fixed;
  right: -200px;
  top: 0;
  height: 100vh;
  width: 200px;
  z-index: 3;
  overflow: scroll;
  transition: var(--transition-standard);
}

aside.menu a {
  padding: var(--spacing-sm);
  background-color: rgba(0, 0, 0, 0.1);
  margin: 3px 0;
  font-size: 1.8em;
  color: white;
  display: block;
  text-decoration: none;
}

aside.menu a:active {
  background-color: rgba(0, 0, 0, 0.3);
}

aside.menu a.item span:first-of-type {
  display: inline-block;
  margin: var(--spacing-sm);
  margin-left: 0;
  vertical-align: middle;
  text-align: right;
  width: 37px;
}

aside.menu a.item span:first-of-type img {
  height: 0.9em;
}

aside.menu img.logo {
  margin: 50px auto;
  width: 80%;
  display: block;
}

aside.menu span {
  display: inline-block;
  vertical-align: middle;
}

aside.menu.visible {
  right: 0;
  transition: var(--transition-standard);
}

aside.menu a.identity span.small {
  font-size: var(--font-size-xs);
}
`, "",{"version":3,"sources":["webpack://./src/app/components/menu/menu.css"],"names":[],"mappings":"AAAA,0BAA0B;;AAE1B;EACE,yDAAqE;EACrE,eAAe;EACf,aAAa;EACb,MAAM;EACN,aAAa;EACb,YAAY;EACZ,UAAU;EACV,gBAAgB;EAChB,sCAAsC;AACxC;;AAEA;EACE,0BAA0B;EAC1B,oCAAoC;EACpC,aAAa;EACb,gBAAgB;EAChB,YAAY;EACZ,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,qBAAqB;EACrB,yBAAyB;EACzB,cAAc;EACd,sBAAsB;EACtB,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,UAAU;EACV,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,sBAAsB;AACxB;;AAEA;EACE,QAAQ;EACR,sCAAsC;AACxC;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":["/* Menu component styles */\n\naside.menu {\n  background-image: url(\"../../../assets/img/menu/background-hdpi.png\");\n  position: fixed;\n  right: -200px;\n  top: 0;\n  height: 100vh;\n  width: 200px;\n  z-index: 3;\n  overflow: scroll;\n  transition: var(--transition-standard);\n}\n\naside.menu a {\n  padding: var(--spacing-sm);\n  background-color: rgba(0, 0, 0, 0.1);\n  margin: 3px 0;\n  font-size: 1.8em;\n  color: white;\n  display: block;\n  text-decoration: none;\n}\n\naside.menu a:active {\n  background-color: rgba(0, 0, 0, 0.3);\n}\n\naside.menu a.item span:first-of-type {\n  display: inline-block;\n  margin: var(--spacing-sm);\n  margin-left: 0;\n  vertical-align: middle;\n  text-align: right;\n  width: 37px;\n}\n\naside.menu a.item span:first-of-type img {\n  height: 0.9em;\n}\n\naside.menu img.logo {\n  margin: 50px auto;\n  width: 80%;\n  display: block;\n}\n\naside.menu span {\n  display: inline-block;\n  vertical-align: middle;\n}\n\naside.menu.visible {\n  right: 0;\n  transition: var(--transition-standard);\n}\n\naside.menu a.identity span.small {\n  font-size: var(--font-size-xs);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
