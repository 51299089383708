'use strict';

/**
 * Volunteer Events Controller
 * Shows events that a sponsor has volunteered for
 */
angular.module('ltcMobileApp.controllers')
    .controller('VolunteerEventsCtrl', ['$scope', '$location', 'UserModel', 'VolunteerService', 'menuUtil', 'dateTimeUtil', function($scope, $location, UserModel, VolunteerService, menuUtil, dateTimeUtil) {
        menuUtil.addMenuController($scope, $location);
        window.scroll(0,0);
        dateTimeUtil.updateNow();
        
        $scope.title = 'My Volunteer Events';
        $scope.events = [];
        $scope.loading = true;
        $scope.error = false;
        
        // Only load volunteer events for sponsors
        if (UserModel.current_user_type === 'sponsor' && UserModel.current_user_key) {
            VolunteerService.loadVolunteerEvents(UserModel.current_user_key)
                .then(function(events) {
                    $scope.events = events;
                    $scope.events.sort(function(a, b) {
                        return a.start_time - b.start_time;
                    });
                    $scope.loading = false;
                })
                .catch(function(error) {
                    console.error('Error loading volunteer events', error);
                    $scope.error = true;
                    $scope.loading = false;
                });
        } else {
            $scope.loading = false;
        }
        
        $scope.formatTime = dateTimeUtil.formatTime;
        $scope.formatDate = dateTimeUtil.conventionDay;
        $scope.getTimeRange = function(event) {
            if (event.duration == 0) {
                return 'all day';
            }
            var end_time = event.get_end_time();
            return dateTimeUtil.formatTime(event.start_time) + ' - ' + dateTimeUtil.formatTime(end_time);
        };
    }]);
