'use strict';

/**
 * Event Detail Controller
 * Shows detailed information about a specific event
 */
angular.module('ltcMobileApp.controllers')
    .controller('EventDetailCtrl', ['$scope', '$location', '$routeParams', 'EventModel', 'StudentModel', 'UserModel', 'menuUtil', 'dateTimeUtil', 'urlUtil', function($scope, $location, $routeParams, EventModel, StudentModel, UserModel, menuUtil, dateTimeUtil, urlUtil) {
        $scope.launchMap = function() {
            urlUtil.launchMap();
        };
        menuUtil.addMenuController($scope, $location);
        window.scroll(0,0);
        dateTimeUtil.updateNow();

        var eventKey = $routeParams.event_key;
        $scope.event = EventModel.events[eventKey];
        $scope.site_name = EventModel.site_name;
        $scope.formatTime = dateTimeUtil.formatTime;
        $scope.formatDate = dateTimeUtil.conventionDay;
        $scope.students = [];
        if (UserModel.current_user_type == 'sponsor') {
            for (var i in StudentModel.students) {
                var student = StudentModel.students[i];
                var exactEventTime = null;
                var exactLocation = null;
                if (student.isAttendingEvent($scope.event)) {
                    student.participations.forEach(function(p) {
                        if (p.event_key == eventKey) {
                            if (p.start_time)
                                student._exact_time = dateTimeUtil.formatTime(p.start_time) + "-" + dateTimeUtil.formatTime(p.get_end_time());
                            if (p.location)
                                student._exact_location = p.location;
                            if (p.team_name)
                                student._team_name = p.team_name;
                            else
                                delete student._team_name;
                        }
                    });
                    $scope.students.push(student);
                }
            }
        }
        $scope.students.sort(StudentModel.student_comparator);
    }]);
