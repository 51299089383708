// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Volunteer events component styles */

section.events div.event {
  padding: var(--spacing-lg);
  border-bottom: 1px white solid;
}

section.events div.name {
  display: table-cell;
  font-size: var(--font-size-lg);
  margin-left: var(--spacing-sm);
  height: 30px;
  padding-left: var(--spacing-sm);
  vertical-align: middle;
}

section.events div.detail {
  font-size: var(--font-size-sm);
  margin: 5px;
  margin-left: 72px;
}

section.events div.detail img {
  height: 1.2em;
  margin-right: 5px;
}

section.events div.detail.strong {
  font-weight: bold;
}

/* Role highlight */
section.events div.detail.strong span {
  color: var(--primary-color-dark);
}
`, "",{"version":3,"sources":["webpack://./src/app/components/volunteer-events/volunteer-events.css"],"names":[],"mappings":"AAAA,sCAAsC;;AAEtC;EACE,0BAA0B;EAC1B,8BAA8B;AAChC;;AAEA;EACE,mBAAmB;EACnB,8BAA8B;EAC9B,8BAA8B;EAC9B,YAAY;EACZ,+BAA+B;EAC/B,sBAAsB;AACxB;;AAEA;EACE,8BAA8B;EAC9B,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA,mBAAmB;AACnB;EACE,gCAAgC;AAClC","sourcesContent":["/* Volunteer events component styles */\n\nsection.events div.event {\n  padding: var(--spacing-lg);\n  border-bottom: 1px white solid;\n}\n\nsection.events div.name {\n  display: table-cell;\n  font-size: var(--font-size-lg);\n  margin-left: var(--spacing-sm);\n  height: 30px;\n  padding-left: var(--spacing-sm);\n  vertical-align: middle;\n}\n\nsection.events div.detail {\n  font-size: var(--font-size-sm);\n  margin: 5px;\n  margin-left: 72px;\n}\n\nsection.events div.detail img {\n  height: 1.2em;\n  margin-right: 5px;\n}\n\nsection.events div.detail.strong {\n  font-weight: bold;\n}\n\n/* Role highlight */\nsection.events div.detail.strong span {\n  color: var(--primary-color-dark);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
