'use strict';

/**
 * Student service
 * Manages student data and relationships to events
 */
angular.module('ltcMobileApp.services')
    .service('StudentModel', ['$http', 'EventModel', function($http, EventModel) {
        var self = this;
        this.students = {};

        this.Student = function(key) {
            this.student_key = key;
            this.first_name = '';
            this.last_name = '';
            this.participations = [];
            this.participations_sorted = false;
            this.login_code = '';
        };

        this.Student.prototype.isAttendingEvent = function(event) {
            for (var i in this.participations) {
                var p = this.participations[i];
                if (p['event_key'] == event['event_key']) {
                    return true;
                }
            }
            return false;
        };

        this.Student.prototype.sort_participations = function() {
            if (this.participations_sorted)
                return;
            this.participations_sorted = true;
            this.participations.sort(EventModel.event_time_comparator);
        };

        this.load_student_data = function(response) {
            if (!(response instanceof Array)) {
                response = [response];
            }
            for (var i in response) {
                var student = response[i];
                if (student) {
                    var student_obj = new self.Student(student['student_key']);
                    student_obj.first_name = student['first_name'];
                    student_obj.last_name = student['last_name'];
                    student_obj.participations = [];
                    var participations = student['participations'];
                    participations.forEach(function(p) {
                        var event_obj = new EventModel.Event(p.event_key);
                        EventModel.load_event_object(event_obj, p);
                        if (p.team_name)
                            event_obj.team_name = p.team_name;
                        student_obj.participations.push(event_obj);
                    });
                    if (student['login_code']) {
                        student_obj.login_code = student['login_code'];
                    }
                    self.students[student['student_key']] = student_obj;
                }
            }
        };

        this.student_comparator = function(a, b) {
            if (a._team_name < b._team_name)  return -1;
            if (b._team_name < a._team_name)  return 1;
            if (a.last_name < b.last_name)    return -1;
            if (b.last_name < a.last_name)    return 1;
            if (a.first_name < b.first_name)  return -1;
            if (b.first_name < a.first_name)  return 1;
            return 0;
        };

        this.clear = function() {
            self.students = {};
        };
    }]);
