// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Students component styles */

section.content a.item {
  padding: 15px;
  border-bottom: 1px white solid;
  font-size: var(--font-size-lg);
  text-decoration: none;
  color: black;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}

section.content a.item span.smaller {
  font-size: 85%;
}

section.content a.item span.rightlight {
  color: var(--text-color-muted);
  font-size: 50%;
  float: right;
}

section.content a.item:active {
  background-color: rgba(0, 0, 0, 0.05);
}
`, "",{"version":3,"sources":["webpack://./src/app/components/students/students.css"],"names":[],"mappings":"AAAA,8BAA8B;;AAE9B;EACE,aAAa;EACb,8BAA8B;EAC9B,8BAA8B;EAC9B,qBAAqB;EACrB,YAAY;EACZ,cAAc;EACd,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,8BAA8B;EAC9B,cAAc;EACd,YAAY;AACd;;AAEA;EACE,qCAAqC;AACvC","sourcesContent":["/* Students component styles */\n\nsection.content a.item {\n  padding: 15px;\n  border-bottom: 1px white solid;\n  font-size: var(--font-size-lg);\n  text-decoration: none;\n  color: black;\n  display: block;\n  white-space: nowrap;\n  overflow: hidden;\n  position: relative;\n}\n\nsection.content a.item span.smaller {\n  font-size: 85%;\n}\n\nsection.content a.item span.rightlight {\n  color: var(--text-color-muted);\n  font-size: 50%;\n  float: right;\n}\n\nsection.content a.item:active {\n  background-color: rgba(0, 0, 0, 0.05);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
