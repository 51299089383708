'use strict';

/**
 * User service
 * Handles user authentication, session management, and data caching
 */
angular.module('ltcMobileApp.services')
    .service('UserModel', ['$http', 'StudentModel', 'EventModel', 'version', 'urlUtil', function($http, StudentModel, EventModel, version, urlUtil) {
        var self = this;
        this.current_user_key = null;
        this.current_user_type = null;
        this.current_user_login_code = '';
        this.parent_mode = false;
        this.current_user_name = '';
        this.registration_codes = [];

        this.check_minor_version_number = function(version, min_app_version) {
            if (!version)
                return false;
            var _version = version.split('.');
            var _min_app_version = min_app_version.split('.');
            if (parseInt(_min_app_version[0]) > parseInt(_version[0]))
                return false;
            if (parseInt(_min_app_version[0]) < parseInt(_version[0]))
                return true;
            if (parseInt(_min_app_version[1]) > parseInt(_version[1]))
                return false;
            if (parseInt(_min_app_version[1]) < parseInt(_version[1]))
                return true;
            return true;
        };

        this.check_version_number = function(version, min_app_version) {
            if (!version)
                return false;
            var _version = version.split('.');
            var _min_app_version = min_app_version.split('.');
            if (parseInt(_min_app_version[0]) > parseInt(_version[0]))
                return false;
            if (parseInt(_min_app_version[0]) < parseInt(_version[0]))
                return true;
            if (parseInt(_min_app_version[1]) > parseInt(_version[1]))
                return false;
            if (parseInt(_min_app_version[1]) < parseInt(_version[1]))
                return true;
            if (parseInt(_min_app_version[2]) > parseInt(_version[2]))
                return false;
            if (parseInt(_min_app_version[2]) < parseInt(_version[2]))
                return true;
            return true;
        };

        if (localStorage['UserModel-users'] != null) {
            this.users = JSON.parse(localStorage['UserModel-users']);
        } else {
            this.users = [];
        }

        this.get_students = function() {
            var students = [];
            for (var i in self.registration_codes) {
                var code = self.registration_codes[i];
                if (code['type'] == 'student') {
                    students.push(code);
                }
            }
            return students;
        };

        this.save_session = function(login_code) {
            localStorage['UserModel-session'] = JSON.stringify({
                'type': self.current_user_type,
                'name': self.current_user_name,
                'login_code': login_code,
                'parent_mode': self.parent_mode,
            });
        };
        this.load_session = function() {
            try {
                var data = JSON.parse(window.localStorage['UserModel-session']);
                self.current_user_type = data['type'];
                self.current_user_name = data['name'];
                self.parent_mode = data['parent_mode'];
                if (self.parent_mode) {
                    self.login_all_students({});
                } else {
                    self.local_login(data['login_code']);
                }
            } catch (e) {
                console.log('Unable to load UserModel from storage');
            }
        };

        this.clear_users = function() {
            self.users = [];
            self.current_user_type = null;
            self.current_user_name = null;
            self.parent_mode = false;
        }

        this.register = function(options) {
            var currentVersion = localStorage['app-version'];
            var request = {
                'device_id': self.get_device_id(),
                'app_version': currentVersion,
            };
            $http.defaults.withCredentials = true;
            console.log(urlUtil.MOBILE_URL_PREFIX+'/register');
            console.info(request);
            $http.post(urlUtil.MOBILE_URL_PREFIX + '/register', request)
                .then(function(response){
                    var data = response.data;
                    console.log('Successfully registered');
                    self.set_device_id(data['device_id']);
                    var newVersion = data['min_app_version'];
                    if (!self.check_minor_version_number(currentVersion, newVersion)) {
                        console.log("Updating minor version from " + currentVersion + " to " + newVersion + " Clearing localStorage");
                        localStorage.clear();
                        self.clear_users();
                    }
                    localStorage['app-version'] = data['min_app_version'];

                    if (!self.check_version_number(currentVersion, newVersion)) {
                        console.log("Updating build version from " + currentVersion + " to " + newVersion + " Clearing cache and reloading page...");
                        if (typeof options['upgrade_required'] === 'function') {
                            options['upgrade_required']();
                        }
                    }
                    localStorage['event_days'] = JSON.stringify(data['days']);
                }, function(result) {
                    console.log('unable to register device'+result);
                });
        };

        this.logout = function() {
            StudentModel.clear();
            EventModel.clear();
        };

        this.login_all_students = function(options) {
            var c = new Completer({
                each: self.get_users('student'),
                concurrent: 1,
                do: function(i, student, next) {
                    self.login(student['login_code'], {
                        'success2': function() { next(); },
                        'error': function() { next(); },
                    });
                },
                done: function() {
                    self.current_user_type = 'sponsor';
                    self.current_user_name = 'Parent Mode'
                    self.parent_mode = true;
                    if (typeof options['success'] === 'function') {
                        options['success']();
                    }
                }
            });
        };

        this.check_user_stale = function(user) {
            if (user == null) {
                return false;
            }
            var date = new Date(user['last_update'] || null);
            if (Date.now() - date > 0) { // (2*60*60*1000)) { // 2 hours
                return true;
            }
            return false;
        };

        this.login = function(login_code, options) {
            var user = self.get_user(login_code);
            var forced_update;
            if (self.check_user_stale(user)) {
                user = null; // force remote update
                forced_update = true;
            }
            if (user == null) {
                self.remote_login(login_code, {
                    'success': function() {
                        if (typeof options['success'] === 'function') {
                            options['success']();
                        }
                    },
                    'success2': function() {
                        if (typeof options['success2'] === 'function') {
                            self.local_login(login_code);
                            options['success2']();
                        }
                    },
                    'error': function() {
                        if (forced_update) { // if we failed but we had data, use that
                            self.local_login(login_code);
                            if (typeof options['success2'] === 'function') {
                                options['success2']();
                            }
                        }
                        if (typeof options['error'] === 'function') {
                            options['error']();
                        }
                    },
                })
            } else {
                self.local_login(login_code);
                if (typeof options['success2'] === 'function') {
                    options['success2']();
                }
            }
        };

        this.local_login = function(login_code) {
            var user = self.get_user(login_code);
            EventModel.site_name = user['login']['site_name'];
            urlUtil.setSiteMapUrl(user['login']['site_map_url'] || '');
            urlUtil.setSiteMapJpgUrl(user['login']['site_map_jpg_url'] || '');
            if (user['type'] == 'student') {
                StudentModel.load_student_data(user['data']);
                self.current_user_type = 'student';
                self.current_user_name = user['login']['first_name'] + ' ' + user['login']['last_name'];
                self.current_user_key = user['login']['student_key'];

            }
            if (user['type'] == 'sponsor' || user['type'] == 'user') {
                StudentModel.load_student_data(user['data']['students']);
                EventModel.load_events(user['event_data']['events']);
                self.current_user_type = 'sponsor';
                self.current_user_name = user['login']['first_name'];
                self.current_user_key = user['login']['sponsor_key'];
            }
            self.current_user_login_code = login_code;
            self.save_session(login_code);
        };

        this.remote_login = function(login_code, options) {
            var login, user_data, event_data;

            function step_one() {
                var request = {
                    'login_code': login_code,
                    'device_id': self.get_device_id(),
                };
                $http.defaults.withCredentials = true;
                $http.post(urlUtil.MOBILE_URL_PREFIX + '/login', request)
                    .then(function(response) {
                        var data = response.data;
                        console.log('login success with code: ' + login_code);
                        login = data;
                        if (typeof options['success'] === 'function') {
                            options['success']();
                        }
                        step_two();
                    }, function() {
                        console.log('login failed');
                        if (typeof options['error'] === 'function') {
                            options['error']();
                        }
                    });
            }

            function step_two() {
                var wait_for;

                $http.defaults.withCredentials = true;
                if (login['type'] == 'student') {
                    wait_for = 1;
                    $http.get(urlUtil.MOBILE_URL_PREFIX + '/student/' + login['student_key'])
                        .then(function(response) {
                            var data = response.data;
                            console.log('successfully loaded student data');
                            user_data = data;
                            done();
                        }, function() {
                            console.log('failed to load student data');
                            done();
                        });
                }

                if (login['type'] == 'sponsor' || login['type'] == 'user') {
                    wait_for = 2;
                    var type = login['type'];
                    var sponsor_key = login['sponsor_key'];
                    $http.get(urlUtil.MOBILE_URL_PREFIX + '/'+type+'/' + login['sponsor_key'])
                        .then(function(response) {
                            var data = response.data;
                            console.log('successfully loaded '+type+' data');
                            user_data = data;
                            done();
                        }, function() {
                            console.log('failed to load '+type+' data');
                            done();
                        });
                    $http.get(urlUtil.MOBILE_URL_PREFIX + '/'+type+'-events/' + login['sponsor_key'])
                        .then(function(response) {
                            var data = response.data;
                            console.log('successfully loaded '+type+' events');
                            event_data = data;
                            done();
                        }, function() {
                            console.log('failed to load '+type+' event details')
                            done();
                        });
                }

                function done() {
                    wait_for -= 1;
                    if (wait_for == 0) {
                        step_three();
                    }
                }
            }

            function step_three() {
                self.save_user(login_code, login, user_data, event_data);
                if (typeof options['success2'] === 'function') {
                    options['success2']();
                }
            }

            step_one();
        };

        this.save_user = function(login_code, login, data, event_data) {
            var user = self.get_user(login_code);
            if (user == null) {
                user = {};
                self.users.push(user);
            }
            user['login'] = login;
            user['data'] = data;
            user['type'] = login['type'];
            user['login_code'] = login_code;
            if (event_data) {
                user['event_data'] = event_data;
            }
            user['last_update'] = new Date(Date.now());
            localStorage['UserModel-users'] = JSON.stringify(self.users);
        };

        this.get_user = function(login_code) {
            for (var i in self.users) {
                if (self.users[i]['login_code'] == login_code) {
                    return self.users[i];
                }
            }
            return null;
        };

        this.get_users = function(type) {
            var users = [];
            for (var i in self.users) {
                if (type && type != self.users[i]['login']['type']) {
                    continue;
                }
                users.push({
                    'name': self.users[i]['login']['first_name'],
                    'login_code': self.users[i]['login_code'],
                    'type': self.users[i]['login']['type'],
                });
            }
            return users;
        };

        this.clear_all = function() {
            self.users = [];
            localStorage['UserModel-users'] = JSON.stringify(self.users);
        };

        this.get_device_id = function() {
            return localStorage['device_id'] || '';
        };
        this.set_device_id = function(device_id) {
            localStorage['device_id'] = device_id;
        };

        this.load_session();
    }]);
