// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 
 * CSS Variables
 * Defines color schemes and other variables used throughout the application
 */

:root {
  /* Colors */
  --primary-color: #679ba4;
  --primary-color-light: #78c7d0;
  --primary-color-dark: #196d76;
  --secondary-color: #516c84;
  
  /* Background colors */
  --bg-gradient-light: #e8ebeb;
  --bg-gradient-mid: #cdced4;
  
  /* Text colors */
  --text-color: #292c31;
  --text-color-light: rgba(255, 255, 255, 0.6);
  --text-color-muted: gray;
  
  /* Spacing */
  --spacing-xs: 5px;
  --spacing-sm: 10px;
  --spacing-md: 15px;
  --spacing-lg: 20px;
  --spacing-xl: 40px;
  
  /* Font sizes */
  --font-size-xs: 0.8em;
  --font-size-sm: 1.2em;
  --font-size-md: 1.5em;
  --font-size-lg: 2em;
  --font-size-xl: 3em;
  
  /* Shadows */
  --shadow-standard: 0 2px 1px 0px rgba(0, 0, 0, 0.25);
  
  /* Transitions */
  --transition-standard: 500ms;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/variables.css"],"names":[],"mappings":"AAAA;;;EAGE;;AAEF;EACE,WAAW;EACX,wBAAwB;EACxB,8BAA8B;EAC9B,6BAA6B;EAC7B,0BAA0B;;EAE1B,sBAAsB;EACtB,4BAA4B;EAC5B,0BAA0B;;EAE1B,gBAAgB;EAChB,qBAAqB;EACrB,4CAA4C;EAC5C,wBAAwB;;EAExB,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;;EAElB,eAAe;EACf,qBAAqB;EACrB,qBAAqB;EACrB,qBAAqB;EACrB,mBAAmB;EACnB,mBAAmB;;EAEnB,YAAY;EACZ,oDAAoD;;EAEpD,gBAAgB;EAChB,4BAA4B;AAC9B","sourcesContent":["/* \n * CSS Variables\n * Defines color schemes and other variables used throughout the application\n */\n\n:root {\n  /* Colors */\n  --primary-color: #679ba4;\n  --primary-color-light: #78c7d0;\n  --primary-color-dark: #196d76;\n  --secondary-color: #516c84;\n  \n  /* Background colors */\n  --bg-gradient-light: #e8ebeb;\n  --bg-gradient-mid: #cdced4;\n  \n  /* Text colors */\n  --text-color: #292c31;\n  --text-color-light: rgba(255, 255, 255, 0.6);\n  --text-color-muted: gray;\n  \n  /* Spacing */\n  --spacing-xs: 5px;\n  --spacing-sm: 10px;\n  --spacing-md: 15px;\n  --spacing-lg: 20px;\n  --spacing-xl: 40px;\n  \n  /* Font sizes */\n  --font-size-xs: 0.8em;\n  --font-size-sm: 1.2em;\n  --font-size-md: 1.5em;\n  --font-size-lg: 2em;\n  --font-size-xl: 3em;\n  \n  /* Shadows */\n  --shadow-standard: 0 2px 1px 0px rgba(0, 0, 0, 0.25);\n  \n  /* Transitions */\n  --transition-standard: 500ms;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
