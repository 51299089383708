'use strict';

angular.module('ltcMobileApp')
    .config(['$routeProvider', '$locationProvider', 
        function($routeProvider, $locationProvider) {
            // Enable HTML5 mode to completely remove the hash
            $locationProvider.html5Mode(true);
            $routeProvider
                .when('/login/:code?', {
                    templateUrl: 'app/components/login/login.template.html',
                    controller: 'LoginCtrl'
                })
                .when('/events', {
                    templateUrl: 'app/components/events/events.template.html',
                    controller: 'EventsCtrl'
                })
                .when('/events/student/:student_key', {
                    templateUrl: 'app/components/events/events.template.html',
                    controller: 'EventsCtrl'
                })
                .when('/event-detail/:event_key', {
                    templateUrl: 'app/components/events/event-detail.template.html',
                    controller: 'EventDetailCtrl'
                })
                .when('/students', {
                    templateUrl: 'app/components/students/students.template.html',
                    controller: 'StudentCtrl'
                })
                .when('/volunteer-events', {
                    templateUrl: 'app/components/volunteer-events/volunteer-events.template.html',
                    controller: 'VolunteerEventsCtrl'
                })
                .otherwise({
                    redirectTo: '/login'
                });
        }
    ]);
