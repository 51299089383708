'use strict';

/**
 * Utility service for URL handling
 */
angular.module('ltcMobileApp.services')
    .service('urlUtil', [function() {
        var self = this;
        
        // Constants
        this.MOBILE_URL_PREFIX = '/mobile';
        this.DESKTOP = 3;
        
        // Properties
        this.site_map_url = '';
        this.site_map_jpg_url = '';

        /**
         * Set the site map URL
         * @param {string} url - The map URL
         */
        this.setSiteMapUrl = function(url) {
            self.site_map_url = self.formatMapUrl(url);
        };

        /**
         * Set the site map JPG URL
         * @param {string} url - The map JPG URL
         */
        this.setSiteMapJpgUrl = function(url) {
            self.site_map_jpg_url = self.formatMapUrl(url);
        };

        /**
         * Ensure map URLs are properly formatted
         * @param {string} url - The map URL
         * @returns {string} Formatted map URL
         */
        this.formatMapUrl = function(url) {
            return '/assets/img/maps/'+url;
        };

        /**
         * Get the device type
         * @returns {number} Device type constant
         */
        this.deviceType = function() {
            return self.DESKTOP;
        };

        /**
         * Launch the map in a new window
         */
        this.launchMap = function() {
            if (self.site_map_url && self.site_map_url.length !== 0) {
                self.launchUrl(self.site_map_url);
            }
        };

        /**
         * Launch a URL in the appropriate context
         * @param {string} url - URL to launch
         */
        this.launchUrl = function(url) {
            // Check if the URL is relative (doesn't start with http:// or https://)
            if (url && !url.match(/^(http|https):\/\//)) {
                // If it's a relative URL, prepend the base href
                if (url.startsWith('/')) {
                    // If it starts with a slash, replace it with the base href
                    url = '/m' + url;
                } else {
                    // Otherwise, append to the base href
                    url = '/m/' + url;
                }
            }
            
            if (self.deviceType() === self.DESKTOP) {
                window.open(url, '_system');
            }
        };
    }]);
