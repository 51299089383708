'use strict';

/**
 * App Version Directive
 * Displays the current app version
 */
angular.module('ltcMobileApp.directives')
    .directive('appVersion', function(version) {
        return function(scope, elm, attrs) {
            elm.text(version);
        };
    });
