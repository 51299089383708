'use strict';

/**
 * Interpolate Filter
 * Replaces %VERSION% with the current app version
 */
angular.module('ltcMobileApp.filters')
    .filter('interpolate', function(version) {
        return function(text) {
            return String(text).replace(/\%VERSION\%/mg, version);
        };
    });
