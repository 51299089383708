'use strict';

/**
 * Modern replacement for the Completer utility
 * Provides utilities for handling asynchronous operations
 */
angular.module('ltcMobileApp')
    .factory('asyncUtil', [function() {
        return {
            /**
             * Process an array of items with a given function, with concurrency control
             * @param {Array} items - Array of items to process
             * @param {Function} processFn - Function to process each item (should return a Promise)
             * @param {Object} options - Options object
             * @param {Number} options.concurrency - Maximum number of concurrent operations
             * @returns {Promise} - Promise that resolves when all items are processed
             */
            processItems: async function(items, processFn, options = {}) {
                const concurrency = options.concurrency || 0; // 0 means no limit
                
                if (!concurrency) {
                    // Process all items in parallel
                    return Promise.all(items.map((item, index) => processFn(index, item)));
                }
                
                // Process items with concurrency limit
                const results = [];
                const inProgress = new Set();
                const itemQueue = [...items];
                
                async function processNext() {
                    if (itemQueue.length === 0) return;
                    
                    const item = itemQueue.shift();
                    const index = items.indexOf(item);
                    
                    const processPromise = processFn(index, item);
                    inProgress.add(processPromise);
                    
                    try {
                        const result = await processPromise;
                        results[index] = result;
                    } catch (error) {
                        console.error('Error processing item:', error);
                    } finally {
                        inProgress.delete(processPromise);
                        
                        if (itemQueue.length > 0) {
                            await processNext();
                        }
                    }
                }
                
                // Start initial batch of concurrent operations
                const initialBatch = Math.min(concurrency, items.length);
                const initialPromises = [];
                
                for (let i = 0; i < initialBatch; i++) {
                    initialPromises.push(processNext());
                }
                
                // Wait for all operations to complete
                await Promise.all([...initialPromises, ...inProgress]);
                
                return results;
            }
        };
    }]);
