// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 
 * Typography styles
 * Defines text styles used throughout the application
 */

body {
  font-size: 12px;
  font-family: Helvetica, Arial, sans-serif;
  color: var(--text-color);
}

h1, h2 {
  margin: 0 10px;
  text-align: center;
}

a {
  text-decoration: none;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-light {
  color: var(--text-color-light);
}

.text-muted {
  color: var(--text-color-muted);
}

.text-smaller {
  font-size: var(--font-size-xs);
}

.text-small {
  font-size: var(--font-size-sm);
}

.text-medium {
  font-size: var(--font-size-md);
}

.text-large {
  font-size: var(--font-size-lg);
}

.text-xl {
  font-size: var(--font-size-xl);
}

.font-bold {
  font-weight: bold;
}

.nowrap {
  white-space: nowrap;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/typography.css"],"names":[],"mappings":"AAAA;;;EAGE;;AAEF;EACE,eAAe;EACf,yCAAyC;EACzC,wBAAwB;AAC1B;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["/* \n * Typography styles\n * Defines text styles used throughout the application\n */\n\nbody {\n  font-size: 12px;\n  font-family: Helvetica, Arial, sans-serif;\n  color: var(--text-color);\n}\n\nh1, h2 {\n  margin: 0 10px;\n  text-align: center;\n}\n\na {\n  text-decoration: none;\n}\n\n.text-center {\n  text-align: center;\n}\n\n.text-right {\n  text-align: right;\n}\n\n.text-light {\n  color: var(--text-color-light);\n}\n\n.text-muted {\n  color: var(--text-color-muted);\n}\n\n.text-smaller {\n  font-size: var(--font-size-xs);\n}\n\n.text-small {\n  font-size: var(--font-size-sm);\n}\n\n.text-medium {\n  font-size: var(--font-size-md);\n}\n\n.text-large {\n  font-size: var(--font-size-lg);\n}\n\n.text-xl {\n  font-size: var(--font-size-xl);\n}\n\n.font-bold {\n  font-weight: bold;\n}\n\n.nowrap {\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
