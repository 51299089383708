'use strict';

/**
 * Volunteer service
 * Manages volunteer event data for sponsors
 */
angular.module('ltcMobileApp.services')
    .service('VolunteerService', ['$http', 'EventModel', 'urlUtil', function($http, EventModel, urlUtil) {
        var self = this;
        this.volunteerEvents = [];
        
        /**
         * Load volunteer events for a sponsor
         * @param {string} sponsorKey - The sponsor's key
         * @returns {Promise} - Promise that resolves with volunteer events
         */
        this.loadVolunteerEvents = function(sponsorKey) {
            return $http.get(urlUtil.MOBILE_URL_PREFIX + '/sponsor-volunteer/' + sponsorKey)
                .then(function(response) {
                    var data = response.data;
                    console.log('Successfully loaded volunteer events');
                    self.volunteerEvents = self.processVolunteerEvents(data.events);
                    return self.volunteerEvents;
                })
                .catch(function(error) {
                    console.error('Failed to load volunteer events', error);
                    return [];
                });
        };
        
        /**
         * Process volunteer events data from the server
         * @param {Array} events - Raw volunteer events data
         * @returns {Array} - Processed volunteer events
         */
        this.processVolunteerEvents = function(events) {
            if (!events || !Array.isArray(events)) {
                return [];
            }
            
            return events.map(function(event) {
                var eventObj = new EventModel.Event(event.event_key);
                EventModel.load_event_object(eventObj, event);
                eventObj.role = event.role || 'Volunteer';
                return eventObj;
            });
        };
        
        /**
         * Get all volunteer events
         * @returns {Array} - All volunteer events
         */
        this.getVolunteerEvents = function() {
            return self.volunteerEvents;
        };
        
        /**
         * Clear all volunteer events
         */
        this.clear = function() {
            self.volunteerEvents = [];
        };
    }]);
