'use strict';

/**
 * Utility service for date and time formatting
 */
angular.module('ltcMobileApp')
    .factory('dateTimeUtil', [function() {
        const service = {
            now: new Date(),
            
            /**
             * Format time in 12-hour format with AM/PM
             * @param {Date} date - Date object to format
             * @returns {string} Formatted time string
             */
            formatTime: function(date) {
                if (!date) return '';
                
                let hours = date.getHours();
                let meridiem = 'AM';
                let mins = date.getMinutes();
                
                if (mins < 10) {
                    mins = '0' + mins;
                }
                
                if (hours >= 12) {
                    hours -= 12;
                    meridiem = 'PM';
                }
                
                if (hours === 0) {
                    hours = 12;
                }
                
                return hours + ':' + mins + ' ' + meridiem;
            },
            
            /**
             * Format date in full format (e.g., "Monday, January 1")
             * @param {Date} date - Date object to format
             * @returns {string} Formatted date string
             */
            formatDate: function(date) {
                if (!date) return '';
                
                const months = ['January', 'February', 'March', 'April', 'May', 'June', 
                               'July', 'August', 'September', 'October', 'November', 'December'];
                const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                
                return days[date.getDay()] + ', ' + months[date.getMonth()] + ' ' + date.getDate();
            },
            
            /**
             * Get day name (e.g., "Friday")
             * @param {Date} date - Date object
             * @returns {string} Day name
             */
            conventionDay: function(date) {
                if (!date) return '';
                
                const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                return days[date.getDay()];
            },
            
            /**
             * Update the current time reference
             */
            updateNow: function() {
                service.now = new Date();
                return service.now;
            }
        };
        
        return service;
    }]);
