'use strict';

/**
 * Student Controller
 * Lists students and their upcoming events
 */
angular.module('ltcMobileApp.controllers')
    .controller('StudentCtrl', ['$scope', '$location', '$rootScope', 'StudentModel', 'menuUtil', 'dateTimeUtil', function($scope, $location, $rootScope, StudentModel, menuUtil, dateTimeUtil) {
        menuUtil.addMenuController($scope, $location);
        window.scroll(0,0);

        $scope.students = [];
        for (var i in StudentModel.students) {
            $scope.students.push(StudentModel.students[i]);
        }
        $scope.updateTimes = function() {
            dateTimeUtil.updateNow();
            $rootScope.$applyAsync();
            setTimeout($scope.updateTimes, 60000);
        }
        $scope.updateTimes();

        $scope.students.sort(StudentModel.student_comparator);
        $scope.get_next_event = function(student) {
            student.sort_participations();
            var nextEvent = null;
            for (var i in student.participations) {
                var event = student.participations[i];
                if (event.get_end_time() > dateTimeUtil.now) {
                    nextEvent = event;
                    break;
                }
            }
            if (nextEvent) {
                var minutesAway = nextEvent.start_time.getTime() - dateTimeUtil.now.getTime();
                minutesAway /= 1000 * 60;
                minutesAway = Math.floor(minutesAway);
                if (minutesAway > 1440) { // a day
                    return {
                        time: 'first event ' + dateTimeUtil.conventionDay(nextEvent.start_time) + ' ' + dateTimeUtil.formatTime(nextEvent.start_time),
                        name: event.event_name
                    };
                }
                if (minutesAway > 120) {
                    return {
                        time: 'in ' + Math.floor(minutesAway / 60) + ' hours',
                        name: event.event_name
                    };
                }
                if (minutesAway <= 0) {
                    return {
                        time: 'happening now',
                        name: event.event_name
                    }
                }
                return {
                    time: 'in ' + minutesAway + ' minutes',
                    name: event.event_name
                };
            } else {
                return {
                    time: 'no upcoming events',
                    name: null
                };
            }
        };
    }]);
