'use strict';

/**
 * Sponsor service
 * Manages sponsor data
 */
angular.module('ltcMobileApp.services')
    .service('SponsorModel', [function() {
        this.Sponsor = function(key) {
            this.key = key;
            this.first_name = '';
            this.last_name = '';
        };
    }]);
