'use strict';

/**
 * Events Controller
 * Manages event display and filtering
 */
angular.module('ltcMobileApp.controllers')
    .controller('EventsCtrl', ['$scope', '$http', '$location', '$routeParams', 'EventModel', 'StudentModel', 'UserModel', 'menuUtil', 'dateTimeUtil', 'urlUtil', function($scope, $http, $location, $routeParams, EventModel, StudentModel, UserModel, menuUtil, dateTimeUtil, urlUtil) {
        $scope.launchMap = function() {
            urlUtil.launchMap();
        };
        if (UserModel.current_user_key == '') {
            $location.path('/login');
        }
        window.scroll(0,0);
        dateTimeUtil.updateNow();

        menuUtil.addMenuController($scope, $location);
        var selected_day, days, student, ls_days;
        $scope.events = [];
        $scope.site_name = EventModel.site_name;
        days = [];
        ls_days = JSON.parse(localStorage['event_days']);
        for (var i in ls_days) {
            days.push({
                'date': ls_days[i],
                'dow': ls_days[i].split(',')[0],
                'dom': ls_days[i].split(',')[1],
                'events': [],
                'number': ['one','two'][i],
                'index': i,
            });
        }

        $scope.title = 'All Events';
        $scope.hide_back = true;

        if ($routeParams.student_key) {
            $scope.hide_back = false;
            student = StudentModel.students[$routeParams.student_key];
            $scope.title = 'Events for ' + student.first_name + ' ' + student.last_name;
            if (student.login_code.length > 0) {
                $scope.student_name = student.first_name + ' ' + student.last_name;
                $scope.login_code = student.login_code;
            }
        } else if (UserModel.current_user_type == 'student') {
            student = StudentModel.students[UserModel.current_user_key];
        }

        if (student) {
            student.participations.forEach(function(p) {
                var day = null;
                for (var d in days) {
                    if (days[d]['date'] == p['day']) {
                        day = days[d];
                    }
                }
                if (day != null) {
                    day['events'].push(p);
                }
            });
        } else {
            for (var e in EventModel.events) {
                var event = EventModel.events[e];
                var day = null;
                for (var d in days) {
                    if (days[d]['date'] == event['day']) {
                        day = days[d];
                    }
                }
                if (day != null) {
                    day['events'].push(event);
                }
            }
        }

        $scope.days = days;
        days[0].events.sort(EventModel.event_time_comparator);
        days[1].events.sort(EventModel.event_time_comparator);

        // Track which day is selected
        var event_day_selected = 0;
        
        $scope.select_day = function(day) {
            for (var i in days) {
                days[i]['active'] = false;
            }
            day['active'] = true;
            $scope.events = day.events;
            event_day_selected = day['index'];
        };

        if (event_day_selected !== null && event_day_selected !== undefined) {
            $scope.select_day(days[event_day_selected]);
        } else {
            $scope.select_day(days[0]);
        }

        $scope.nav_event = function(event) {
            if (UserModel.current_user_type == 'student')
                return;
            if (student)
                return;
            $location.path('event-detail/' + event.event_key)
        };

        $scope.formatTime = dateTimeUtil.formatTime;
        $scope.formatDate = dateTimeUtil.conventionDay;
        $scope.getTimeRange = function(event) {
            if (event.duration == 0) {
                return 'all day';
            }
            var end_time = event.get_end_time();
            return dateTimeUtil.formatTime(event.start_time) + ' - ' + dateTimeUtil.formatTime(end_time);
        };
    }]);
