'use strict';

/**
 * Menu utility functions
 * Provides common menu functionality for controllers
 */
angular.module('ltcMobileApp')
    .factory('menuUtil', [function() {
        return {
            /**
             * Add menu controller functionality to a scope
             * @param {Object} scope - The controller scope
             * @param {Object} location - The $location service
             */
            addMenuController: function(scope, location) {
                scope.menu = {};
                scope.menu.url = '/m/app/components/menu/menu.template.html';
                scope.menu_visible = false;
                scope.toggle_menu = function() {
                    scope.menu_visible = !scope.menu_visible;
                };
                scope.back = function() {
                    if (scope.hide_back) {
                        console.log('back action suppressed');
                        return;
                    }
                    window.history.back();
                };
                scope.version = localStorage['app-version'];
                
                // Navigation functions
                scope.navigateTo = function(path) {
                    scope.menu_visible = false;
                    location.path(path);
                };
                
                scope.navigateToStudent = function(studentKey) {
                    scope.menu_visible = false;
                    location.path('events/student/' + studentKey);
                };
            }
        };
    }]);
