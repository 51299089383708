// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 
 * Button styles
 * Defines button styles used throughout the application
 */

button {
  margin: var(--spacing-lg) auto;
  width: 80%;
  display: block;
  color: white;
  text-align: center;
  padding: 0.7em;
  font-size: var(--font-size-lg);
  text-transform: uppercase;
  letter-spacing: 0.10em;
  background-color: var(--primary-color);
  border: none;
  border-top: solid 1px var(--primary-color-light);
  box-shadow: var(--shadow-standard);
}

button img {
  float: right;
  height: 20px;
  margin: 4px 0;
}

button:active {
  background-color: var(--primary-color-dark);
}

.btn-secondary {
  background-color: var(--secondary-color);
}

.btn-small {
  font-size: var(--font-size-md);
  padding: 0.5em;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/buttons.css"],"names":[],"mappings":"AAAA;;;EAGE;;AAEF;EACE,8BAA8B;EAC9B,UAAU;EACV,cAAc;EACd,YAAY;EACZ,kBAAkB;EAClB,cAAc;EACd,8BAA8B;EAC9B,yBAAyB;EACzB,sBAAsB;EACtB,sCAAsC;EACtC,YAAY;EACZ,gDAAgD;EAChD,kCAAkC;AACpC;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,8BAA8B;EAC9B,cAAc;AAChB","sourcesContent":["/* \n * Button styles\n * Defines button styles used throughout the application\n */\n\nbutton {\n  margin: var(--spacing-lg) auto;\n  width: 80%;\n  display: block;\n  color: white;\n  text-align: center;\n  padding: 0.7em;\n  font-size: var(--font-size-lg);\n  text-transform: uppercase;\n  letter-spacing: 0.10em;\n  background-color: var(--primary-color);\n  border: none;\n  border-top: solid 1px var(--primary-color-light);\n  box-shadow: var(--shadow-standard);\n}\n\nbutton img {\n  float: right;\n  height: 20px;\n  margin: 4px 0;\n}\n\nbutton:active {\n  background-color: var(--primary-color-dark);\n}\n\n.btn-secondary {\n  background-color: var(--secondary-color);\n}\n\n.btn-small {\n  font-size: var(--font-size-md);\n  padding: 0.5em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
