'use strict';

// Define the main application module
angular.module('ltcMobileApp', [
    'ngRoute',
    'ltcMobileApp.controllers',
    'ltcMobileApp.filters',
    'ltcMobileApp.services',
    'ltcMobileApp.directives'
]);

// Create the sub-modules
angular.module('ltcMobileApp.controllers', []);
angular.module('ltcMobileApp.filters', []);
angular.module('ltcMobileApp.services', []);
angular.module('ltcMobileApp.directives', []);
